<template>
    <div>
        <CCard>
            <CCardBody>
                <h4>LAYANAN USULAN</h4>
                <hr class="line-blue">
                <CRow class="mt-2">
                    <CCol lg="3" class="mt-3" v-for="data,i in menus" :key="i">
                        <div class="card-menu" :style="('background:'+data.color)" @click="$router.push(data.path)">
                            <div class="card-body-menu">
                                <img :src="'/img/'+data.icon" alt="image" class="icon-menu">
                                <p class="mb-0">{{ data.name }}</p>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
export default {
    name: "MenuIcon",
    components: {},
    data() {
        return {
            menus: [
                // {
                //     id: 1,
                //     name: 'Usulan Kenaikan Pangkat',
                //     icon: 'kenaikan-pangkat.png',
                //     color: '#136ED9',
                //     path: '/usulan-layanan/usulan-kenaikan-pangkat'
                // },
                // {
                //     id: 2,
                //     name: 'Usulan Mutasi Masuk',
                //     icon: 'mutasi.png',
                //     color: '#EF7452',
                //     path: '/usulan-layanan/usulan-mutasi-masuk'
                // },
                // {
                //     id: 3,
                //     name: 'Usulan Mutasi Keluar',
                //     icon: 'mutasi.png',
                //     color: '#2BBDAD',
                //     path: '/usulan-layanan/usulan-mutasi-keluar'
                // },
                // {
                //     id: 4,
                //     name: 'Pencantuman Gelar',
                //     icon: 'pencantuman-gelar.png',
                //     color: '#17758E',
                //     path: '/usulan-layanan/pencantuman-gelar'
                // },
                // {
                //     id: 5,
                //     name: 'P&P SK Kenaikan Pangkat',
                //     icon: 'penerbitan.png',
                //     color: '#2BBDAD',
                //     path: '/usulan-layanan/perbaikan-pergantian'
                // },
                // {
                //     id: 6,
                //     name: 'P&P SK Pengangkatan',
                //     icon: 'penerbitan.png',
                //     color: '#136ED9',
                //     path: '/usulan-layanan/perbaikan-pergantian-pengangkatan'
                // },
                {
                    id: 7,
                    name: 'Penerbitan SK Pensiun',
                    icon: 'penerbitan.png',
                    color: '#17758E',
                    path: '/usulan-layanan/usulan-penerbitan-sk-pensiun'
                },
                {
                    id: 8,
                    name: 'SKUMPTK',
                    icon: 'penerbitan.png',
                    color: '#EF7452',
                    path: '/usulan-layanan/usulan-skumptk'
                }
            ]
        };
    },
    mounted() {
    },
    watch: {
    },
    computed: {
    },
    methods: {
    }
};
</script>
<style scoped>
.line-blue{
    margin-top: 0;
    margin-bottom: 0;
    border-top: 5px solid #3B7AEA;
    width: 25%;
    border-radius: 5px;
    margin-left: 0;
}
.card-menu{
    /* background: #3B7AEA; */
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}
.card-body-menu{
    display: flex;
    align-items: center;
}
.icon-menu{
    width: 50px !important;
    height: 50px !important;
    margin-right: 15px;
}
</style>